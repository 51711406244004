import React, { useEffect, useState } from 'react';
import { FaShareAlt, FaSearchPlus } from 'react-icons/fa';
import { products } from './ProductSection.js';
import './ProductDetails.css';
import { useParams } from 'react-router-dom';

const ProductDetails = ({ addToCart }) => {
    const [quantity, setQuantity] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [stock, setStock] = useState(null);

    const { id } = useParams();
    const product = products.find((prod) => prod.id == id);
    const [selectedImage, setSelectedImage] = useState(product.detailImages[0]);

    useEffect(() => {
        window.scrollTo(0, 0);
        setSelectedImage(product.detailImages[0]);
    }, [product]);

    // Fetch stock from backend
    useEffect(() => {
        const fetchStock = async () => {
            try {
                const response = await fetch('https://neteroshealth.com/backend/api.php?action=getStock');
                const data = await response.json();
                setStock(data.stock || 10); // Set stock value or 10 if undefined
            } catch (error) {
                console.error("Failed to fetch stock", error);
                setStock(0); // Default to 0 in case of an error
            }
        };
        fetchStock();
    }, []);

    const decreaseQuantity = () => {
        setQuantity(quantity > 1 ? quantity - 1 : 1);
    };

    const increaseQuantity = () => {
        setQuantity(quantity + 1);
    };

    const handleAddToCart = () => {
        const productToAdd = { ...product, quantity: product.isPack ? 1 : quantity };
        addToCart(productToAdd);
        window.scrollTo(0, 0);
    };

    const handleThumbnailClick = (imageSrc) => {
        setSelectedImage(imageSrc);
    };

    const handleZoomClick = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="product-details">
            {isModalOpen && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close-modal" onClick={closeModal}>
                            &times;
                        </span>
                        <img src={selectedImage} alt="Zoomed Product" />
                    </div>
                </div>
            )}
            <div className="main-content">
                <div className="image-gallery">
                    <div className="thumbnail-list">
                    {product?.detailImages?.map((imageSrc, index) => (
                        <img
                            key={index}
                            src={imageSrc || '/placeholder.png'}
                            alt={`Thumbnail ${index + 1}`}
                            className={selectedImage === imageSrc ? 'active-thumbnail' : ''}
                            onClick={() => handleThumbnailClick(imageSrc)}
                        />
                    ))}
                    </div>
                    <div className="main-image" onClick={handleZoomClick}>
                        <img src={selectedImage} alt="Main Product" />
                    </div>
                </div>

                <div className="product-info">
                    <h2>{product.name}</h2>
                    <p className="price">
                        {product.price.toFixed(2)} dh 
                        {product.originalPrice && (
                            <span className="original-price2">{product.originalPrice}</span>
                        )}
                    </p>
                    <p className="conseil">
                        Conseils d'Utilisation: Prenez 2 capsules par jour avec de l'eau, 30 minutes avant les repas ou
                        l'entraînement. Ne dépassez pas la dose quotidienne recommandée.
                    </p>

                    <div className="benefits-section">
                        <ul>
                            <li>
                                <img src="/energy.png" alt="Energy" />
                                <span>Augmentation d'énergie</span>
                            </li>
                            <li>
                                <img src="/combustion.png" alt="Fat burning" />
                                <span>Favorise la combustion des graisses</span>
                            </li>
                            <li>
                                <img src="/strong.png" alt="Water retention" />
                                <span>Élimination de l'excès d'eau</span>
                            </li>
                            <li>
                                <img src="/vessel.png" alt="Circulation" />
                                <span>Amélioration de la circulation</span>
                            </li>
                            <li>
                                <img src="/intestines.png" alt="Digestion" />
                                <span>Digestion optimisée</span>
                            </li>
                        </ul>
                    </div>

                    {/* Conditionally Render Quantity Selector */}
                    {!product.isPack && (
                        <>
                            <div className="quantity">
                                <label>Quantité</label>
                                <div className="quantity-selector">
                                    <button onClick={decreaseQuantity}>-</button>
                                    <input type="text" value={quantity} readOnly />
                                    <button onClick={increaseQuantity}>+</button>
                                </div>
                            </div>
                            <p className="stock-info">Il reste environ {stock !== null ? stock : '...'} articles.</p>
                        </>
                    )}


                    <button className="add-to-cart" onClick={handleAddToCart}>
                        Ajouter au panier
                    </button>
                </div>
            </div>
            <h4 className="desc-title">Augmentez Votre Métabolisme de 25%</h4>

            <div className="product-description">
                <div className="description-list">
                    <div className="description-item">
                        <span className="description-number">1</span>
                        <div className="description-content">
                            <h5>Caféine et Thé Vert</h5>
                            <p>Augmentent votre énergie, favorisent la combustion des graisses et éliminent votre excès d'eau.</p>
                        </div>
                    </div>
                    <div className="description-item">
                        <span className="description-number">2</span>
                        <div className="description-content">
                            <h5>Extrait d'Orange Amère</h5>
                            <p>Accélère votre métabolisme pour des résultats rapides.</p>
                        </div>
                    </div>
                    <div className="description-item">
                        <span className="description-number">3</span>
                        <div className="description-content">
                            <h5>Extrait de Poivre de Cayenne</h5>
                            <p>Stimule votre circulation et améliore votre "pump" à l'entraînement et, favorise la combustion des graisses.</p>
                        </div>
                    </div>
                    <div className="description-item">
                        <span className="description-number">4</span>
                        <div className="description-content">
                            <h5>Extrait de Ginseng</h5>
                            <p>Stimule votre énergie et améliore votre endurance.</p>
                        </div>
                    </div>
                    <div className="description-item">
                        <span className="description-number">5</span>
                        <div className="description-content">
                            <h5>Extrait de Graines d'Ananas</h5>
                            <p>Favorise votre digestion des protéines et optimise votre circulation.</p>
                        </div>
                    </div>
                </div>
                <div className="description-image">
                    <img src="/fruits_num.png" alt="Ingredients visualization" />
                </div>
            </div>
        </div>
    );
};

export default ProductDetails;
