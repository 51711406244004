// ProductSection.js
import React from 'react';
import { Link } from 'react-router-dom';
import './ProductSection.css';

const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 >= 0.5;
    const stars = [];

    for (let i = 0; i < fullStars; i++) {
        stars.push(<span key={`star-${i}`} className="star">★</span>);
    }

    if (halfStar) {
        stars.push(<span key="half-star" className="star half">★</span>);
    }

    while (stars.length < 5) {
        stars.push(<span key={`empty-star-${stars.length}`} className="star empty">★</span>);
    }

    return stars;
};

export const products = [
    {
        id: 1,
        sectionImage: '/product10.png',
        detailImages: ['/product11.jpg','/product12.jpg', '/product13.jpg', '/product14.jpg', '/table.png'],
        name: 'Neteros MB25',
        price: 248.00,
        reviews: 4.5,
        reviewCount: 171,
        servings: '90 capsules',
        promoMessage: "FREE EBOOK",
        isPack: false, // Single product
    },
    {
        id: 2,
        sectionImage: '/product20.png',
        detailImages: ['/product11.jpg','/product22.jpg', '/product24.jpg', '/product23.jpg', '/table.png'],
        name: 'Pack Neteros MB25 X2',
        price: 459.00,
        originalPrice: '496.00 dh',
        reviews: 5,
        reviewCount: 11,
        servings: '90 capsules',
        promoMessage: "FREE EBOOK + CARTE COUPON",
        isPack: true, // Product pack
    },
];

const ProductSection = () => {
  

    return (
        <section id="produits" className="product-section">
            {/* <h3>Une énergie au naturel</h3> */}
            <h2>DES PRODUITS À FORMULATIONS UNIQUES !</h2>
            <p>
            Boostez votre bien-être avec des formules inspirées de la nature, conçues pour maximiser votre énergie et vitalité au quotidien.
            </p>
            <div className="product-list">
                {products.map((product) => (
                    <Link to={`/product/${product.id}`} key={product.id} className="product-item">
                        <div className="product-image">
                           {product.discount && <span className="discount">{product.discount}</span>}  
                           <img src={product.sectionImage || '/placeholder.png'} alt={product.name} />
                        </div>
                        <h4>{product.name}</h4>
                        {/* <div className="review-section">
                            <div className="stars">{renderStars(product.reviews)}</div>
                            <span className="review-count">({product.reviewCount})</span>
                        </div> */}
                        {product.servings && <p className="servings">{product.servings}</p>}
                        <p className="price">
                            {product.price}.00 DH <span className="original-price">{product.originalPrice}</span>
                        </p>
                        {product.promoMessage && <p className="promo-message">{product.promoMessage}</p>}
                    </Link>
                ))}
            </div>
        </section>
    );
};

export default ProductSection;
