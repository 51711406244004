import React, { useRef, useState, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './InfluencerPage.css';
import { FaInstagram, FaTiktok } from "react-icons/fa";

const InfluencerPage = () => {
    const formRef = useRef();
    const [notification, setNotification] = useState(null);
    const [influencers, setInfluencers] = useState([]);

    // Fetch influencers data from the PHP backend
    useEffect(() => {
        const fetchInfluencers = async () => {
            try {
                const response = await fetch('https://neteroshealth.com/backend/api.php');
                const data = await response.json(); 
                console.log(data)
                setInfluencers(data);
                  
            } catch (error) {
                console.error("Error fetching influencers data:", error);
            }
        };
        fetchInfluencers();
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm('service_8zkonad', 'template_1rk7xh9', formRef.current, 'Nj5k3BTXjZgpAmtz8')
            .then(() => {
                setNotification({ message: 'Votre candidature a été envoyée avec succès !', type: 'success' });
                setTimeout(() => setNotification(null), 4000);
            }).catch(() => {
                setNotification({ message: 'Une erreur s\'est produite. Veuillez réessayer ultérieurement.', type: 'error' });
                setTimeout(() => setNotification(null), 4000);
            });
        e.target.reset();
    };

    return (
        <div className="responsive-container-block outer-container">
            <div className="responsive-container-block inner-container">
                <h2 className="text-blk section-head-text">Rejoignez Notre Famille</h2>
                <h4 className="text-blk section-subhead-text">
                    Nous collaborons avec des influenceurs passionnés. Rejoignez-nous et grandissons ensemble!
                </h4>

                {/* Team Members */}
                <div className="responsive-container-block">
                    {influencers.map((influencer) => (
                        <div key={influencer.id} className="responsive-cell-block team-card-container">
                            <div className="team-card">
                                <div className="img-wrapper">
                                    <img className="team-img" src={influencer.image} alt={influencer.name} />
                                </div>
                                <p className="text-blk name">{influencer.name}</p>
                                <p className="text-blk position">{influencer.position}</p>
                                <div className="social-media-links">
                                    <a href={influencer.social.instagram} target="_blank" rel="noreferrer">
                                        <FaInstagram className="social-icon" />
                                    </a>
                                    <a href={influencer.social.tiktok} target="_blank" rel="noreferrer">
                                        <FaTiktok className="social-icon" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Form Section */}
                <div className="form-container">
                    <form ref={formRef} className="contact-form" onSubmit={sendEmail}>
                        {notification && (
                            <div className={`toast-notification ${notification.type}`}>
                                {notification.message}
                            </div>
                        )}
                        <h3 className="form-title">Devenez Membre!</h3>
                        <div className="input-row">
                            <input
                                type="text"
                                name="from_name"
                                placeholder="Votre Nom"
                                className="placeholder-style"
                                required
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                className="placeholder-style"
                                required
                            />
                        </div>
                        <textarea
                            name="message"
                            className="placeholder-style2"
                            placeholder="N'oubliez pas de mettre vos comptes de réseaux sociaux (Ex: Insta: @xxxx)"
                        ></textarea>
                        <button type="submit" className="submit-button">
                            Rejoignez-nous
                        </button>

                    </form>
                </div>
            </div>
        </div>
    );
};

export default InfluencerPage;
