import React, { useState } from 'react';
import './FooterSection.css';
import { FaFacebookF, FaInstagram, FaTiktok } from 'react-icons/fa';

const FooterSection = () => {
    const [showLinks, setShowLinks] = useState(false);

    const toggleLinks = () => {
        setShowLinks(!showLinks);
    };

    return (
        <footer className="footer-section">
            <div className="footer-bottom">
                <p>&copy;NETEROSHEALTH 2024</p>
                <div className="social-icons">
                    <a href="https://www.facebook.com/share/1DEfTYrW3J/?mibextid=wwXIfr" target="_blank" rel="noopener noreferrer">
                                                    <FaFacebookF />
                                                </a>
                                                <a href="https://www.instagram.com/neteroshealth/" target="_blank" rel="noopener noreferrer">
                                                    <FaInstagram />
                                                </a>
                                                <a href="https://www.tiktok.com/@neteros.health" target="_blank" rel="noopener noreferrer">
                                                    <FaTiktok />
                                                </a>
                </div>
            </div>
        </footer>
    );
};

export default FooterSection;
