import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './CartDropdown.css';
import { FaShoppingCart } from 'react-icons/fa';

const CartDropdown = ({ cartItems, setCartItems, setCartOpen }) => {
    const navigate = useNavigate();
    const dropdownRef = useRef(null);

    const goToCheckout = () => {
        setCartOpen(false);
        navigate('/checkout');
    };

    const handleCloseDropdown = () => {
        setCartOpen(false);
    };
    const handleQuantityChange = (id, newQuantity) => {
        setCartItems(prevItems =>
            prevItems.map(item =>
                item.id === id ? { ...item, quantity: newQuantity } : item
            )
        );
    };

    return (
        <div class="icon-container">
        <div className="cart-dropdown" ref={dropdownRef}>
            {cartItems.length > 0 ? (
                <>
                    <ul>
                        {cartItems.map(item => (
                            <li key={item.id} className="cart-item">
                                <img
                                    src={item.sectionImage || '/placeholder.png'}
                                    alt={item.name}
                                    className="cart-item-image"
                                />
                                <div className="cart-item-details">
                                    <span className="cart-item-name">{item.name}</span>
                                    <div className="cart-item-quantity">
                                        <button onClick={() => handleQuantityChange(item.id, item.quantity>1? item.quantity- 1:1)}>
                                            -
                                        </button>
                                        <span>{item.quantity}</span>
                                        <button onClick={() => handleQuantityChange(item.id, item.quantity + 1)}>
                                            +
                                        </button>
                                    </div>
                                    <span className="cart-item-price">{item.price * item.quantity} dh</span>
                                    <button
                                        className="remove-item"
                                        onClick={() =>
                                            setCartItems(cartItems.filter(cartItem => cartItem.id !== item.id))
                                        }
                                    >
                                        Retirer
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="cart-subtotal">
                        <span>Sous-total:</span>
                        <span>{cartItems.reduce((total, item) => total + item.price * item.quantity, 0)} dh</span>
                    </div>
                    <button className="checkout-button" onClick={goToCheckout}>
                        Finaliser la commande
                    </button>
                </>
            ) : (
                <div className="empty-cart">
                    <FaShoppingCart className="empty-cart-icon" />
                    <span>  Votre panier est vide.</span>
                </div>
            )}
        </div>
        </div>
    );
};

export default CartDropdown;
