import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import HomePage from './pages/HomePage';
import ProductDetails from './components/ProductDetails';
import ScrollToTopButton from './components/ScrollToTopButton';
import CheckoutPage from './components/CheckoutPage';
import ContactPage from './components/ContactPage';
import InfluencerPage from './components/InfluencerPage';

function App() {

    const [cartOpen, setCartOpen] = useState(false);

    const [cartItems, setCartItems] = useState(() => {
        const savedCart = localStorage.getItem('cartItems');
        return savedCart ? JSON.parse(savedCart) : []; 
    });

    useEffect(() => {
        const script = document.createElement("script");
        script.async = true;
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-NS26Y2DJZ5";
        document.head.appendChild(script);
      
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag("js", new Date());
        gtag("config", "G-NS26Y2DJZ5");
      }, []);
      

    useEffect(() => {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }, [cartItems]);
    
    const addToCart = (newItem) => {
        setCartItems((prevItems) => {
            const existingItem = prevItems.find(item => item.id === newItem.id);
            if (existingItem) {
                return prevItems.map(item =>
                    item.id === newItem.id
                        ? { ...item, quantity:  item.quantity+newItem.quantity }
                        : item
                );
            }
            return [...prevItems, newItem];
        },
    setCartOpen(true));
    };

    return (
        <Router>
            <Header cartItems={cartItems} setCartItems={setCartItems} cartOpen={cartOpen} setCartOpen={setCartOpen} />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route 
                    path="/product/:id" 
                    element={<ProductDetails addToCart={addToCart} cartItems={cartItems} />} 
                />
                <Route path="/checkout" element={<CheckoutPage cartItems={cartItems} setCartItems={setCartItems}/>}  />
                <Route path="/contact" element={<ContactPage />} />
                <Route path="/notre-famille" element={<InfluencerPage />} />
            </Routes>
            <ScrollToTopButton /> 
        </Router>
    );
}

export default App;
